import { push } from 'connected-react-router/immutable';

import toolService from 'services/tool.service';
import taskService from 'services/task.service';
import reportsService from 'services/reports.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import toolstringService from 'services/toolstring.service';
import { receiveTask } from 'features/projects/tasks/tasks.actions';
import { toTaskToolstringEdit, toTaskToolstring } from 'utils/route.util';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';

export const getTaskById = (projectId, taskId) => (dispatch) => {
  const payload = taskService
    .getTaskById(projectId, taskId)
    .then((task) => dispatch(receiveTask(task)));

  dispatch({
    type: TOOLSTRING_ACTIONS.GET_TASK_BY_ID,
    payload,
  });

  return payload;
};

export const getToolstring =
  (
    projectId,
    taskId,
    toolstringId,
    options = {
      silent: false,
    },
  ) =>
  (dispatch) => {
    const payload = toolstringService.getToolString(
      projectId,
      taskId,
      toolstringId,
    );

    dispatch({
      payload,
      silent: options.silent,
      type: TOOLSTRING_ACTIONS.GET_TOOLSTRING_FOR_TASK,
    });

    return payload;
  };

export const getToolstringsForTask = (projectId, taskId) => (dispatch) => {
  const payload = toolstringService.getToolStringsForTask(projectId, taskId);

  dispatch({
    type: TOOLSTRING_ACTIONS.GET_TOOLSTRINGS_FOR_TASK,
    payload,
    taskId,
  });

  return payload;
};

export const getCables = () => (dispatch) => {
  const payload = () => toolService.getCables();

  dispatch({
    type: TOOLSTRING_ACTIONS.GET_CABLES,
    payload,
  });

  return payload;
};

export const redirectToTaskToolstring = (projectId, taskId, toolstringId) =>
  push(toTaskToolstring(projectId, taskId, toolstringId));

export const redirectToTaskToolstringEdit = (projectId, taskId, toolstringId) =>
  push(toTaskToolstringEdit(projectId, taskId, toolstringId));

/**
 *  Sagas actions
 */

export const receiveTaskToolstrings = (projectId, taskId, toolstrings) => ({
  taskId,
  projectId,
  payload: toolstrings,
  type: TOOLSTRING_ACTIONS.RECEIVE_TASK_TOOLSTRINGS,
});

export const receiveTaskToolstring = (projectId, taskId, toolstring) => ({
  taskId,
  projectId,
  payload: toolstring,
  type: TOOLSTRING_ACTIONS.RECEIVE_TASK_TOOLSTRING,
});

export const requestGetTaskToolstrings = (projectId, taskId) => ({
  taskId,
  projectId,
  type: TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRINGS_FOR_TASK,
});

export const requestGetTaskToolstring = (
  projectId,
  taskId,
  toolstringId,
  isMobilise = false,
) => ({
  taskId,
  projectId,
  toolstringId,
  isMobilise,
  type: TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
});

export const requestTaskToolstringSections = (
  projectId,
  taskId,
  toolstringId,
) => ({
  taskId,
  projectId,
  toolstringId,
  type: TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_SECTIONS,
});

export const receiveTaskToolstringSections = (
  projectId,
  taskId,
  toolstringId,
  sections,
) => ({
  taskId,
  projectId,
  toolstringId,
  payload: sections,
  type: TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_SECTIONS,
});

export const requestCreateToolstringForTask = (
  projectId,
  taskId,
  toolstring,
) => ({
  projectId,
  taskId,
  toolstring,
  type: TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_FOR_TASK,
});

export const submitCreateToolstringForTask = (
  projectId,
  taskId,
  toolstring,
  setSubmitting,
  setStatus,
) => ({
  projectId,
  taskId,
  toolstring,
  setSubmitting,
  setStatus,
  type: TOOLSTRING_ACTIONS.SUBMIT_CREATE_TOOLSTRING_FOR_TASK,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Creating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The BHA was successfully created',
  },
});

export const submitCreateToolstringFromTemplateForTask = (
  projectId,
  taskId,
  toolstringTemplateId,
) => ({
  projectId,
  taskId,
  toolstringTemplateId,
  type: TOOLSTRING_ACTIONS.SUBMIT_CREATE_TOOLSTRING_FROM_TEMPLATE_FOR_TASK,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Creating BHA from Template...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The BHA was successfully created',
  },
});

export const requestToogleToolstringFavorite = (
  projectId,
  taskId,
  toolstringId,
) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_TOGGLE_TOOLSTRING_FAVORITE,
  projectId,
  taskId,
  toolstringId,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating favorite...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA favorite updated succesfully',
  },
});

export const requestToggleToolstringItemBreakpoint = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_TOGGLE_TOOLSTRING_ITEM_BREAKPOINT,
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating breakpoint...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA breakpoint updated succesfully',
  },
});

export const requestDuplicateToolstringAndRedirect = (
  projectId,
  taskId,
  toolstringId,
) => ({
  taskId,
  projectId,
  toolstringId,
  type: TOOLSTRING_ACTIONS.REQUEST_DUPLICATE_TOOLSTRING_FOR_TASK_AND_REDIRECT,
});

export const requestDeleteToolstringAndRedirect = (
  projectId,
  taskId,
  toolstringId,
  activeToolstringId,
) => ({
  taskId,
  projectId,
  toolstringId,
  activeToolstringId,
  confirmationDialog: {
    title: 'Delete BHA',
    confirmButtonText: 'Delete',
    description: 'Are you sure you want to delete this BHA?',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Deleting BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA successfully deleted!',
  },
  type: TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_FOR_TASK_AND_REDIRECT,
});

export const requestUpdateToolstring = (
  projectId,
  taskId,
  toolstringId,
  toolstring,
) => ({
  projectId,
  taskId,
  toolstringId,
  toolstring,
  type: TOOLSTRING_ACTIONS.REQUEST_UPDATE_TOOLSTRING_FOR_TASK,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA updated succesfully',
  },
});

export const requestCreateToolstringTool = (
  projectId,
  taskId,
  toolstringId,
  tool,
  sequenceNumber,
) => {
  const toolName = tool.get('name');

  return {
    projectId,
    taskId,
    toolstringId,
    tool,
    sequenceNumber,
    type: TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_TOOL,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `${toolName} was successfully added to BHA`,
    },
  };
};

export const requestCreateToolAttachment = (
  projectId,
  taskId,
  toolstringId,
  owner,
  tool,
) => {
  return {
    projectId,
    taskId,
    toolstringId,
    owner,
    tool,
    type: TOOLSTRING_ACTIONS.REQUEST_ATTACH_TOOL,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `${tool.get(
        'name',
      )} was successfully attached to ${owner.get('name')}`,
    },
  };
};

export const requestDeleteToolstringAttachment = (
  projectId,
  taskId,
  toolstringId,
  ownerId,
  toolstringItemId,
) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ATTACHMENT,
  projectId,
  taskId,
  toolstringId,
  ownerId,
  toolstringItemId,
  confirmationDialog: {
    title: 'Remove attachment',
    description: 'Are you sure you want to remove this attachment?',
    confirmButtonText: 'Remove',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The attachment was successfully removed',
  },
});

export const requestSortToolstringAttachments = (
  projectId,
  taskId,
  toolstringId,
  ownerId,
  sortedAttachment,
  affectedAttachment,
) => ({
  projectId,
  taskId,
  toolstringId,
  ownerId,
  sortedAttachment,
  affectedAttachment,
  type: TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_ATTACHMENTS,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Moving attachment...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA sequence successfully updated!',
  },
});

export const requestCreateToolstringTemplateAttachment = (
  toolstringId,
  owner,
  tool,
) => {
  return {
    toolstringId,
    owner,
    tool,
    type: TOOLSTRING_ACTIONS.REQUEST_ATTACH_TOOL_TO_TEMPLATE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `${tool.get(
        'name',
      )} was successfully attached to template ${owner.get('name')}`,
    },
  };
};

export const requestDeleteToolstringTemplateAttachment = (
  toolstringId,
  ownerId,
  toolstringItemId,
) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_TEMPLATE_ATTACHMENT,
  toolstringId,
  ownerId,
  toolstringItemId,
  confirmationDialog: {
    title: 'Remove attachment from template',
    description:
      'Are you sure you want to remove this attachment from template?',
    confirmButtonText: 'Remove',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]:
      'The attachment was successfully removed from template',
    [NOTIFICATION_VARIANTS.ERROR]: 'Error on removing attachment from template',
  },
});

export const requestSortToolstringTemplateAttachments = (
  toolstringId,
  ownerId,
  sortedAttachment,
  affectedAttachment,
) => ({
  toolstringId,
  ownerId,
  sortedAttachment,
  affectedAttachment,
  type: TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TEMPLATE_ATTACHMENTS,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Moving attachment...',
    [NOTIFICATION_VARIANTS.SUCCESS]:
      'BHA template sequence successfully updated!',
    [NOTIFICATION_VARIANTS.ERROR]: 'Error on moving attachment on BHA template',
  },
});

export const requestUpdateToolstringItem = (
  projectId,
  taskId,
  toolstringId,
  toolstringItem,
) => {
  const name = toolstringItem.name;
  const m3Number = toolstringItem.m3ItemNumber;

  return {
    projectId,
    taskId,
    toolstringId,
    toolstringItem,
    type: TOOLSTRING_ACTIONS.REQUEST_UPDATE_TOOLSTRING_ITEM,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `#${m3Number} - ${name} was successfully updated`,
    },
  };
};

export const requestUpdatePlannedToolstringItem = (
  projectId,
  taskId,
  toolstringId,
  toolstringItem,
) => {
  const name = toolstringItem.name;
  const m3Number = toolstringItem.m3ItemNumber;

  return {
    projectId,
    taskId,
    toolstringId,
    toolstringItem,
    type: TOOLSTRING_ACTIONS.REQUEST_UPDATE_PLANNED_TOOLSTRING_ITEM,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `#${m3Number} - ${name} was successfully updated`,
    },
  };
};

export const requestUpdateNotPlannedToolstringItem = (
  projectId,
  taskId,
  toolstringId,
  toolstringItem,
) => {
  const name = toolstringItem.name;
  const m3Number = toolstringItem.m3ItemNumber;

  return {
    projectId,
    taskId,
    toolstringId,
    toolstringItem,
    type: TOOLSTRING_ACTIONS.REQUEST_UPDATE_NOT_PLANNED_TOOLSTRING_ITEM,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `#${m3Number} - ${name} was successfully updated`,
    },
  };
};

export const requestDuplicateToolstringItem = (
  projectId,
  taskId,
  toolstringId,
  tool,
) => {
  const toolName = tool.get('name');

  return {
    projectId,
    taskId,
    toolstringId,
    tool,
    type: TOOLSTRING_ACTIONS.REQUEST_DUPLICATE_TOOLSTRING_ITEM,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `${toolName} was successfully duplicated`,
    },
  };
};

export const requestDeleteToolstringTool = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ITEM,
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  confirmationDialog: {
    title: 'Remove tool',
    description: 'Are you sure you want to remove this tool?',
    confirmButtonText: 'Remove',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The tool was successfully removed',
  },
});

export const requestDeleteToolstringAssemblyTool = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  toolAssemblyToolId,
) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ASSEMBLY_TOOL,
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  toolAssemblyToolId,
  confirmationDialog: {
    title: 'Remove tool from assembly',
    description:
      'Are you sure you want to remove this tool from the BHA assembly?',
    confirmButtonText: 'Remove',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The tool was successfully removed',
  },
});

export const requestTaskToolstringsPageOnLoad = (projectId, taskId) => ({
  type: TOOLSTRING_ACTIONS.REQUEST_TASK_TOOLSTRINGS_PAGE_ON_LOAD,
  projectId,
  taskId,
});

export const requestSortToolstringTools = (
  projectId,
  taskId,
  toolstringId,
  sortedTool,
  affectedTool,
) => ({
  projectId,
  taskId,
  toolstringId,
  sortedTool,
  affectedTool,
  type: TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TOOLS,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Moving tool...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA sequence successfully updated!',
  },
});

export const receiveToolstringToolsUpdatedSequences = (
  toolstringId,
  updatedToolstringToolSequences,
) => ({
  type: TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_TOOLS_UPDATED_SEQUENCES,
  toolstringId,
  payload: updatedToolstringToolSequences,
});

export const requestSortToolstringAssemblyTools = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  sortedToolstringAssemblyTool,
  affectedToolstringAssemblyTool,
) => ({
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  sortedToolstringAssemblyTool,
  affectedToolstringAssemblyTool,
  type: TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_ASSEMBLY_TOOLS,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool sequence successfully updated!',
  },
});

export const requestCreateToolstringAssemblyTool = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  tool,
) => ({
  tool,
  taskId,
  projectId,
  toolstringId,
  toolstringItemId,
  type: TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_ASSEMBLY_TOOL,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool successfully added!',
  },
});

export const receiveToolstringItem = (
  projectId,
  taskId,
  toolstringId,
  toolstringItem,
) => ({
  taskId,
  projectId,
  toolstringId,
  payload: toolstringItem,
  type: TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_ITEM,
});

export const receiveToolstringAssemblyTool = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  toolstringAssemblyTool,
) => ({
  taskId,
  projectId,
  toolstringId,
  toolstringItemId,
  payload: toolstringAssemblyTool,
  type: TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_ASSEMBLY_TOOL,
});

export const downloadToolstringPdfReport =
  (projectId, taskId, toolstringId, collapseAssemblies) => (dispatch) => {
    const payload = reportsService.downloadProjectToolstringReport(
      projectId,
      taskId,
      toolstringId,
      collapseAssemblies,
    );

    dispatch({
      type: TOOLSTRING_ACTIONS.DOWNLOAD_TOOLSTRING_PDF_REPORT,
      payload,
    });
  };

export const requestToolstringTemplates = () => ({
  type: TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_TEMPLATES,
});

export const receiveToolstringTemplates = (templates) => ({
  payload: templates,
  type: TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_TEMPLATES,
});

export const receiveToolstringTemplateByID = (templateID) => ({
  payload: toolstringService.getToolstringTemplateById(templateID),
  type: TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_TEMPLATE_BY_ID,
});

export const updateToolStringToolStatus = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  status,
) => {
  return {
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    status,
    type: TOOLSTRING_ACTIONS.UDPATE_TOOLSTRING_TOOL_STATUS,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating status...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Status successfully updated!',
    },
  };
};

export const addStringTestAssetsToToolstring = (assets) => ({
  payload: assets,
  type: TOOLSTRING_ACTIONS.ADD_ASSETS_TO_TOOLSTRING,
});
