import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { searchM3ItemsWithDispatch } from 'features/equipment/equipment.actions';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableSpoolingMaintenanceDrawerComponent = ({ assetHistory, classes }) => {
  const dispatch = useDispatch();
  const [drumName, setDrumName] = useState('');

  useEffect(() => {
    const itemNumber = assetHistory.getIn(['drumAsset', 'itemNumber']);
    dispatch(searchM3ItemsWithDispatch(itemNumber)).then((response) => {
      setDrumName(
        response.find((m3) => m3.m3ItemNumber === itemNumber).m3ItemName,
      );
    });
  }, [assetHistory, dispatch]);
  return (
    <>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Drum Asset"
            className={classes?.marginLabel}
          >
            {drumName}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Drum Item Number"
            className={classes?.marginLabel}
          >
            {assetHistory.getIn(['drumAsset', 'itemNumber'])}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Drum Serial Number"
            className={classes?.marginLabel}
          >
            {assetHistory.getIn(['drumAsset', 'serialNumber'])}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
    </>
  );
};

export default CableSpoolingMaintenanceDrawerComponent;
