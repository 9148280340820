import { memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import HistoryIcon from '@material-ui/icons/History';
import withStyles from '@material-ui/styles/withStyles';

import {
  Grid,
  MenuItem,
  TextField,
  IconButton,
  ListItemText,
} from '@material-ui/core';

import { withModal } from 'altus-ui-components';

import {
  PROJECT_STATUS,
  ProjectPermission,
  ProjectWellboreDetailStatus,
  EMPTY_MAP,
} from 'app/app.constants';

import {
  ACTIONS,
  PROJECT_WELLBORE_DETAIL_CHANGELOG_MODAL_ID,
} from 'features/projects/wellbore/details/projectWellboreDetails.constants';
import { ACTIONS as ACTIONS_SEC } from 'features/projects/wellbore/sections/projectWellboreSection.constants';
import {
  createAndRedirectProjectWellboreDetailDraft,
  getProjectWellboreDetailChangelog,
} from 'features/projects/wellbore/details/projectWellboreDetails.actions';

import {
  getProjectWellboreDetailFromState,
  getProjectWellboreDetailsForProjectFromState,
} from 'features/projects/wellbore/details/projectWellboreDetails.selectors';

import { formatDate } from 'utils/format.util';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import ProjectWellboreDetailChangelogModal from 'features/projects/wellbore/details/components/ProjectWellboreDetailChangelogModal';

export const ProjectWellboreDetailStatusName = {
  [ProjectWellboreDetailStatus.DRAFT]: 'Draft',
  [ProjectWellboreDetailStatus.APPROVED]: 'Approved',
  [ProjectWellboreDetailStatus.REQUIRES_APPROVAL]: 'Requires Approval',
};

const NO_PROJECT_WELLBORE_DETAIL_SELECTED = '';

const ProjectWellboreDetailHeader = ({
  classes,
  projectId,
  toggleModal,
  project = EMPTY_MAP,
  handleActiveWellboreId,
  projectWellboreDetailId,
  projectWellboreDetails = EMPTY_MAP,
  currentProjectWellboreDetail = EMPTY_MAP,
  dispatchCreateAndRedirectProjectWellboreDetailDraft,
}) => {
  const projectStatus = project.get('status');
  const status = currentProjectWellboreDetail.get('status');

  const selectedItem = projectWellboreDetails.has(
    projectWellboreDetailId?.toString(),
  )
    ? projectWellboreDetailId
    : NO_PROJECT_WELLBORE_DETAIL_SELECTED;

  const displayCreateDraftBtn =
    projectStatus === PROJECT_STATUS.PLAN &&
    status === ProjectWellboreDetailStatus.APPROVED;

  return (
    <Grid
      item
      container
      spacing={2}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Grid item>
        <ReadOnlyField
          margin="none"
          label="Status"
          value={ProjectWellboreDetailStatusName[status]}
        />
      </Grid>
      <Grid item>
        <TextField
          select
          margin="none"
          label="Change"
          value={selectedItem}
          onChange={(event) => handleActiveWellboreId(event.target.value)}
        >
          {projectWellboreDetails.keySeq().map((id, index) => {
            const change = projectWellboreDetails.size - index;
            return (
              <MenuItem key={id} value={id}>
                <ListItemText
                  primary={change}
                  classes={{
                    root: classes.listItemTextRoot,
                  }}
                />
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          label="Time"
          value={formatDate(currentProjectWellboreDetail.get('revisionDate'))}
        />
      </Grid>
      {displayCreateDraftBtn && (
        <HasProjectPermission permissions={ProjectPermission.EDIT_WELL_DETAILS}>
          <Grid item>
            <IconButton
              title="New draft"
              onClick={() =>
                dispatchCreateAndRedirectProjectWellboreDetailDraft(
                  projectId,
                  projectWellboreDetailId,
                )
              }
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </HasProjectPermission>
      )}
      <Grid item>
        <IconButton title="Display changelog" onClick={toggleModal}>
          <HistoryIcon />
        </IconButton>
      </Grid>
      <HasProjectPermission permissions={ProjectPermission.EXPORT_WELL_DETAILS}>
        <Grid item>
          {/* TODO: enable when implementing export functionality */}
          <IconButton title="Export" disabled>
            <SaveAltIcon />
          </IconButton>
        </Grid>
      </HasProjectPermission>
      <ProjectWellboreDetailChangelogModal
        project={project}
        projectId={projectId}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  [`root-${ProjectWellboreDetailStatus.DRAFT}`]: {
    background: theme.altus.statusColor[ProjectWellboreDetailStatus.DRAFT],
  },
  [`root-${ProjectWellboreDetailStatus.REQUIRES_APPROVAL}`]: {
    background:
      theme.altus.statusColor[ProjectWellboreDetailStatus.REQUIRES_APPROVAL],
  },
  [`root-${ProjectWellboreDetailStatus.APPROVED}`]: {
    background: theme.altus.statusColor[ProjectWellboreDetailStatus.APPROVED],
  },
});

export default compose(
  connect(
    (state, { projectId, projectWellboreDetailId }) => ({
      currentProjectWellboreDetail: getProjectWellboreDetailFromState(
        state,
        projectWellboreDetailId,
      ),
      projectWellboreDetails: getProjectWellboreDetailsForProjectFromState(
        state,
        projectId,
      ),
      dataState: getSummarizedDataStateFromState(
        state,
        ACTIONS.LOAD_PROJECT_WELLBORE_DETAILS_PAGE,
        ACTIONS.SUBMIT_PROJECT_WELLBORE_DETAIL_FORM,
        ACTIONS.APPROVE_PROJECT_WELLBORE_DETAIL_DRAFT,
        ACTIONS.REQUEST_PROJECT_WELLBORE_DETAIL_DRAFT_APPROVAL,
        ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT,
        ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT_AND_SYNC,

        // ACTIONS_SEC.SYNC_PROJECT_WELLBORE_SECTIONS_WITH_LATEST,
      ),
    }),
    {
      dispatchGetProjectWellboreDetailChangelog:
        getProjectWellboreDetailChangelog,
      dispatchCreateAndRedirectProjectWellboreDetailDraft:
        createAndRedirectProjectWellboreDetailDraft,
    },
  ),
  withModal(PROJECT_WELLBORE_DETAIL_CHANGELOG_MODAL_ID),
  memo,
  withStyles(styles),
)(ProjectWellboreDetailHeader);
