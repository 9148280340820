import { useMemo } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  Dialog,
  Button,
  DialogContent,
  withStyles,
  Typography,
} from '@material-ui/core';
import { ModalHeader, ModalActions, LoadingButton } from 'altus-ui-components';
import { Form, Formik, Field, useFormikContext, ErrorMessage } from 'formik';
import { convertToRaw } from 'draft-js';

import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import DatePickerFormik from 'app/components/form/formik/DatePickerFormik';
import TimeSpanFormik from 'app/components/form/formik/TimeSpanFormik';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { Avatar as TooltipAvatar } from 'app/components/withTooltip';
import {
  getSummarizedDataStateFromState,
  getCurrentUserFromState,
  getCurrentClientSelectedUom,
} from 'app/app.selectors';
import { createAssetHistory } from 'features/equipment/equipment.actions';
import {
  EQUIPMENT_ACTIONS,
  ASSET_HISTORY_EVENT_TYPES,
  ASSET_HISTORY_EVENT_TYPES_ITEM,
} from 'features/equipment/equipment.constants';
import { useParams } from 'react-router-dom';
import { getTaskById } from 'features/projects/tasks/tasks.selectors';

import TestForm from 'features/equipment/assets/components/TestForm';
import { useViewMode } from 'app/hooks/useViewMode';
import AssetHistoryYupValidator from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryYupValidator';
import AssetHistoryInitialFormValues from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryInitialFormValues';
import AssetHistoryForm from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import AssetHistoryFormValues from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryFormValues';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { getUnitsOfMeasurePreference } from 'features/equipment/equipment.selectors';
import { getUnitForCustomUnit } from 'features/equipment/equipment.helpers';
import {
  CONVERT_MEASURES,
  USE_PROJECT_DEFAULT_UNIT_SYSTEM,
} from 'app/app.constants';
import MaintenanceForm from 'features/equipment/assets/components/MaintenanceForm';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

export const AssetTestType = {
  GENERIC: '1',
  CABLE: '2',
  BOP_TEST: '3',
  TEST_STRING: '4',
  CABLE_TORSION_TEST: '5',
  CABLE_WRAP_TEST: '6',
  CABLE_TORTURE_TEST: '7',
  CABLE_CONTINUITY_TEST: '8',
  CABLE_INSULATION_TEST: '9',
  SIL2_TEST: '10',
  CABLE_WRAP_BRAIDED_MONO_TEST: '11',
  CABLE_BREAK_TEST: '12',
};
export const AssetMaintenanceType = {
  GENERIC: '1',
  CABLE_CUT: '2',
  CABLE_SERVICE: '3',
  CABLE_SPOOLING: '4',
};

const CreateAssetHistoryModalContainer = ({
  toggleModal,
  isOpen,
  user,
  classes,
  serialNumber,
  assetId,
  externalToolId,
  equipmentType,
  getAssetAfterRefresh = true,
  assetHistoryEventTypes = ASSET_HISTORY_EVENT_TYPES,
  testTypesItems,
  toolstringId,
  defaultEventValue = '',
  defaultTestTypeValue = '',
  modalTitle = 'New Event',
  sil2TestBopItems,
  sil2TestBcsItems,
  sil2TestCabinItems,
  maintenanceTypeItems,
}) => {
  const isMobile = useViewMode() === 'mobile';
  const { taskId } = useParams();
  const task = useSelector(getTaskById(taskId));
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const dispatch = useDispatch();

  const projectMeasurementSystem = useSelector(getCurrentProject);
  const measurePreferences = useSelector(getCurrentClientSelectedUom);
  const unitsOfMeasurePreference = useSelector(getUnitsOfMeasurePreference);

  const selectedCustomUnitsOfMeasure =
    USE_PROJECT_DEFAULT_UNIT_SYSTEM === unitsOfMeasurePreference
      ? projectMeasurementSystem
      : measurePreferences;

  const forceUnit = getUnitForCustomUnit(
    CONVERT_MEASURES.FORCE,
    unitsOfMeasurePreference,
    selectedCustomUnitsOfMeasure,
    projectMeasurementSystem,
  );

  const convertEditorContentToJson = (editorContent) => {
    return editorContent
      ? JSON.stringify(convertToRaw(editorContent.getCurrentContent()))
      : null;
  };

  const FormFields = AssetHistoryForm;

  const initialFormValues = useMemo(() => {
    return AssetHistoryInitialFormValues(
      defaultEventValue,
      defaultTestTypeValue,
      forceUnit,
      unitsOfMeasurePreference,
      selectedCustomUnitsOfMeasure,
    );
  }, [
    defaultEventValue,
    defaultTestTypeValue,
    forceUnit,
    selectedCustomUnitsOfMeasure,
    unitsOfMeasurePreference,
  ]);

  const AssetFormContent = ({ defaultTestTypeValue }) => {
    const form = useFormikContext();
    const { values } = form;

    const showTestFields =
      String(values[FormFields.TYPE_OF_EVENT]) ===
      String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST);
    const showMaintenanceFields =
      String(values[FormFields.TYPE_OF_EVENT]) ===
      String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE);

    return (
      <>
        {showTestFields && (
          <>
            <TestForm
              form={form}
              formFields={FormFields}
              onSubmit={handleSubmit}
              testTypesItems={testTypesItems}
              defaultTestTypeValue={defaultTestTypeValue}
              sil2TestBopItems={sil2TestBopItems}
              sil2TestBcsItems={sil2TestBcsItems}
              sil2TestCabinItems={sil2TestCabinItems}
              selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
              unitsOfMeasurePreference={unitsOfMeasurePreference}
              projectMeasurementSystem={projectMeasurementSystem}
            />
          </>
        )}
        {showMaintenanceFields && (
          <MaintenanceForm
            form={form}
            formFields={FormFields}
            onSubmit={handleSubmit}
            maintenanceTypeItems={maintenanceTypeItems}
            defaultTestTypeValue={defaultTestTypeValue}
            selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
            unitsOfMeasurePreference={unitsOfMeasurePreference}
            projectMeasurementSystem={projectMeasurementSystem}
          />
        )}
      </>
    );
  };

  const handleSubmit = (formValues) => {
    const description = convertEditorContentToJson(formValues?.description);
    const bopLeakTypeDescription = convertEditorContentToJson(
      formValues?.bopLeakTypeDescription,
    );

    const assetHistory = AssetHistoryFormValues(
      formValues,
      serialNumber,
      assetId,
      task,
      toolstringId,
      taskId,
      externalToolId,
      description,
      bopLeakTypeDescription,
    );

    dispatch(
      createAssetHistory(assetHistory, equipmentType, getAssetAfterRefresh),
    );
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile ? true : false}
      open={isOpen}
      maxWidth="sm"
      onClose={toggleModal}
    >
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={AssetHistoryYupValidator}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { isValid, isSubmitting } = formik;
          return (
            <Form id={FormFields.ID}>
              {!isMobile ? (
                <ModalHeader title={modalTitle} toggleModal={toggleModal} />
              ) : (
                <Grid container spacing={2} className={classes.statusHeader}>
                  <Grid item xs={2}>
                    <ChevronLeft
                      className={classes.backButtonIcon}
                      onClick={toggleModal}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">{modalTitle}</Typography>
                  </Grid>
                </Grid>
              )}
              <DialogContent>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        {defaultEventValue ? null : (
                          <SelectTextFieldFormik
                            getItemName={getName}
                            placeholder="Select"
                            label="Event"
                            getItemValue={getId}
                            items={assetHistoryEventTypes}
                            name={FormFields.TYPE_OF_EVENT}
                            displayEmpty={false}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <AssetFormContent
                          onSubmit={handleSubmit}
                          defaultTestTypeValue={defaultTestTypeValue}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field name={FormFields.DATE}>
                            {({ form, ...formik }) => (
                              <DatePickerFormik
                                style={{ paddingTop: '3px' }}
                                autoOk
                                label="Date"
                                placeholder="yyyy/mm/dd"
                                form={form}
                                {...formik}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            label="Time"
                            name={FormFields.TIME}
                            component={TimeSpanFormik}
                            placeholder="hh:mm"
                          />
                          <ErrorMessage name={FormFields.TIME}>
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Field
                          label="Comment"
                          name={FormFields.DESCRIPTION}
                          component={EditorFormik}
                          xs={12}
                        />
                      </Grid>
                      <br></br>
                      <Grid
                        container
                        alignItems="center"
                        style={{ height: '100%', marginBottom: '20px' }}
                      >
                        <TooltipAvatar
                          title={user.get('name')}
                          src={user.get('avatar')}
                          classes={{
                            root: classes.triggerIconRoot,
                          }}
                        >
                          {!user.get('avatar') && user.get('initials')}
                        </TooltipAvatar>
                        {user.get('name')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <Grid
                className={
                  isMobile
                    ? classes.gridBackgroundMobile
                    : classes.gridBackground
                }
              >
                <ModalActions className={classes.gridBackground}>
                  <Button
                    size={isMobile ? 'large' : 'small'}
                    color="default"
                    onClick={toggleModal}
                    className={isMobile && classes.containedButtonGrey}
                  >
                    CANCEL
                  </Button>
                  <LoadingButton
                    type="submit"
                    size={isMobile ? 'large' : 'small'}
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                    form={FormFields.ID}
                    disabled={!isValid || isSubmitting}
                    className={isMobile && classes.containedButton}
                  >
                    SAVE
                  </LoadingButton>
                </ModalActions>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const styles = (theme) => ({
  triggerIconRoot: {
    height: 45,
    width: 45,
    marginRight: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  gridBackground: {
    background: theme.altus.components.ApplicationSidebar.background,
  },
  gridBackgroundMobile: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backButtonIcon: {
    color: theme.palette.primary.newLight,
    width: '90%',
    height: '90%',
  },
  statusHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  containedButton: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },
  containedButtonGrey: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.lighter,
    color: theme.palette.secondary.dark,
  },
});

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.CREATE_ASSET_HISTORY,
  ),
  user: getCurrentUserFromState(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CreateAssetHistoryModalContainer);
