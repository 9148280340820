export const TOOLSTRING_ACTIONS = {
  GET_TASK_BY_ID: 'GET_TASK_BY_ID',
  GET_CABLES: 'GET_CABLES',
  GET_BHA_TEMPLATES: 'GET_BHA_TEMPLATES',

  // Tool Attachments
  REQUEST_ATTACH_TOOL: 'REQUEST_ATTACH_TOOL',
  REQUEST_DELETE_TOOLSTRING_ATTACHMENT: 'REQUEST_DELETE_TOOLSTRING_ATTACHMENT',
  REQUEST_SORT_TOOLSTRING_ATTACHMENTS: 'REQUEST_SORT_TOOLSTRING_ATTACHMENTS',
  // Tool Attachments for Templates
  REQUEST_ATTACH_TOOL_TO_TEMPLATE: 'REQUEST_ATTACH_TOOL_TO_TEMPLATE',
  REQUEST_DELETE_TOOLSTRING_TEMPLATE_ATTACHMENT:
    'REQUEST_DELETE_TOOLSTRING_TEMPLATE_ATTACHMENT',
  REQUEST_SORT_TOOLSTRING_TEMPLATE_ATTACHMENTS:
    'REQUEST_SORT_TOOLSTRING_TEMPLATE_ATTACHMENTS',

  // Toolstring tools
  REQUEST_CREATE_TOOLSTRING_TOOL: 'REQUEST_CREATE_TOOLSTRING_TOOL',
  REQUEST_UPDATE_TOOLSTRING_ITEM: 'REQUEST_UPDATE_TOOLSTRING_ITEM',
  REQUEST_UPDATE_PLANNED_TOOLSTRING_ITEM:
    'REQUEST_UPDATE_PLANNED_TOOLSTRING_ITEM',
  REQUEST_UPDATE_NOT_PLANNED_TOOLSTRING_ITEM:
    'REQUEST_UPDATE_NOT_PLANNED_TOOLSTRING_ITEM',
  REQUEST_DUPLICATE_TOOLSTRING_ITEM: 'REQUEST_CREATE_TOOLSTRING_ITEM',
  REQUEST_TOGGLE_TOOLSTRING_ITEM_BREAKPOINT:
    'REQUEST_TOGGLE_TOOLSTRING_ITEM_BREAKPOINT',
  UDPATE_TOOLSTRING_TOOL_STATUS: 'UDPATE_TOOLSTRING_TOOL_STATUS',

  // Toolstring
  GET_TOOLSTRINGS_FOR_TASK: 'GET_TOOLSTRINGS_FOR_TASK',
  GET_TOOLSTRING_FOR_TASK: 'GET_TOOLSTRING_FOR_TASK',
  CREATE_TOOLSTRING_FOR_TASK: 'CREATE_TOOLSTRING_FOR_TASK',
  CREATE_TOOLSTRING_FROM_TEMPLATE_FOR_TASK:
    'CREATE_TOOLSTRING_FROM_TEMPLATE_FOR_TASK',
  SUBMIT_CREATE_TOOLSTRING_FOR_TASK: 'SUBMIT_CREATE_TOOLSTRING_FOR_TASK',
  SUBMIT_CREATE_TOOLSTRING_FROM_TEMPLATE_FOR_TASK:
    'SUBMIT_CREATE_TOOLSTRING_FROM_TEMPLATE_FOR_TASK',
  DELETE_TOOLSTRING_FOR_TASK: 'DELETE_TOOLSTRING_FOR_TASK',
  UPDATE_TOOLSTRING_FOR_TASK: 'UPDATE_TOOLSTRING_FOR_TASK',

  REQUEST_TASK_TOOLSTRINGS_PAGE_ON_LOAD:
    'REQUEST_TASK_TOOLSTRINGS_PAGE_ON_LOAD',
  REQUEST_UPDATE_TOOLSTRING_FOR_TASK: 'REQUEST_UPDATE_TOOLSTRING_FOR_TASK',
  REQUEST_GET_TOOLSTRINGS_FOR_TASK: 'REQUEST_GET_TOOLSTRINGS_FOR_TASK',
  REQUEST_GET_TOOLSTRING_FOR_TASK: 'REQUEST_GET_TOOLSTRING_FOR_TASK',
  REQUEST_CREATE_TOOLSTRING_FOR_TASK: 'REQUEST_CREATE_TOOLSTRING_FOR_TASK',
  REQUEST_CREATE_TOOLSTRING_FOR_TASK_FROM_TEMPLATE:
    'REQUEST_CREATE_TOOLSTRING_FOR_TASK_FROM_TEMPLATE',
  REQUEST_DUPLICATE_TOOLSTRING_FOR_TASK_AND_REDIRECT:
    'REQUEST_DUPLICATE_TOOLSTRING_FOR_TASK',
  REQUEST_DELETE_TOOLSTRING_FOR_TASK_AND_REDIRECT:
    'REQUEST_DELETE_TOOLSTRING_FOR_TASK',
  REQUEST_TOGGLE_TOOLSTRING_FAVORITE: 'REQUEST_TOGGLE_TOOLSTRING_FAVORITE',
  RECEIVE_TASK_TOOLSTRING: 'RECEIVE_TASK_TOOLSTRING',
  RECEIVE_TASK_TOOLSTRINGS: 'RECEIVE_TASK_TOOLSTRINGS',
  REQUEST_DELETE_TOOLSTRING_ITEM: 'REQUEST_DELETE_TOOLSTRING_ITEM',
  REQUEST_SORT_TOOLSTRING_TOOLS: 'REQUEST_SORT_TOOLSTRING_TOOLS',
  RECEIVE_TOOLSTRING_TOOLS_UPDATED_SEQUENCES:
    'RECEIVE_TOOLSTRING_TOOLS_UPDATED_SEQUENCES',

  RECEIVE_TOOLSTRING_ITEM: 'RECEIVE_TOOLSTRING_ITEM',
  RECEIVE_TOOLSTRING_ASSEMBLY_TOOL: 'RECEIVE_TOOLSTRING_ASSEMBLY_TOOL',
  REQUEST_SORT_TOOLSTRING_ASSEMBLY_TOOLS:
    'REQUEST_SORT_TOOLSTRING_ASSEMBLY_TOOLS',
  REQUEST_TOOLSTRING_SECTIONS: 'REQUEST_TOOLSTRING_SECTIONS',
  RECEIVE_TOOLSTRING_SECTIONS: 'RECEIVE_TOOLSTRING_SECTIONS',
  REQUEST_CREATE_TOOLSTRING_ASSEMBLY_TOOL:
    'REQUEST_CREATE_TOOLSTRING_ASSEMBLY_TOOL',
  REQUEST_DELETE_TOOLSTRING_ASSEMBLY_TOOL:
    'REQUEST_DELETE_TOOLSTRING_ASSEMBLY_TOOL',

  DOWNLOAD_TOOLSTRING_PDF_REPORT: 'DOWNLOAD_TOOLSTRING_PDF_REPORT',

  REQUEST_TOOLSTRING_TEMPLATES: 'REQUEST_TOOLSTRING_TEMPLATES',
  RECEIVE_TOOLSTRING_TEMPLATES: 'RECEIVE_TOOLSTRING_TEMPLATES',
  RECEIVE_TOOLSTRING_TEMPLATE_BY_ID: 'RECEIVE_TOOLSTRING_TEMPLATE_BY_ID',

  // ASSETS
  ADD_ASSETS_TO_TOOLSTRING: 'ADD_ASSETS_TO_TOOLSTRING',
};

export const MODAL = {
  CREATE_TOOLSTRING_MODAL_ID: 'CREATE_TOOLSTRING_MODAL_ID',
  SIMULATION_EXPORT_MODAL_ID: 'SIMULATION_EXPORT_MODAL_ID',
};

export const TOOLSTRING_FORMS = {
  TOOL_SEARCH_FILTER_FORM: {
    FORM_ID: 'toolSearch',
    TOOL_TYPE: 'toolType',
    BOTTOM_CONNECTOR: 'bottomConnector',
    TOP_CONNECTOR: 'topConnector',
  },
  CREATE_TOOLSTRING_FORM: {
    TOOLSTRING_NAME: 'name',
  },
  EDIT_TOOLSTRING_FORM: {
    FORM_ID: 'editToolstring',
    TOOLSTRING_NAME: 'name',
    TOOLSTRING_CABLE_GROUP: 'cableType',
    TOOLSTRING_CABLE_TOOL: 'cableId',
    TOOLSTRING_COMMENT: 'comment',
  },
};

export const CREATE_TOOLSTRING_FORM = {
  FORM_ID: 'createToolstring',
  TOOLSTRING_NAME: 'name',
  TOOLSTRING_DESCRIPTION: 'description',
  TOOLSTRING_WIRE_DIMENSION: 'wireDescription',
  TOOLSTRING_WIRE_TYPE: 'wireType',
  TOOLSTRING_CABLE_GROUP: 'cableType',
  TOOLSTRING_CABLE_TOOL: 'cableId',
};

export const TOOLSTRING_OPTION_ACTIONS = {
  ADD_TOOL: 1,
  DUPLICATE_ITEM: 2,
  ASSET_HISTORY: 3,
  REMOVE_TOOL: 4,
  ATTACH_TOOL: 5,
};

export const TOOL_STATUS = {
  NULL_ASSIGNED: 0,
  NOT_ASSIGNED: 1,
  SET: 2,
  RETRIEVE: 3,
};
