import { ProjectPermission, SystemPermission } from 'app/app.constants';

export const ROLE_ACCESS_TYPES = {
  NORMAL: 0,
  PROTECTED: 1,
};

export const PermissionType = {
  SYSTEM: 0,
  PROJECT: 1,
};

export const AUTHORIZATION_ACTIONS = {
  GET_SYSTEM_ROLES: 'GET_SYSTEM_ROLES',
  GET_SYSTEM_ROLE: 'GET_SYSTEM_ROLE',
  CREATE_SYSTEM_ROLE: 'CREATE_SYSTEM_ROLE',
  DELETE_SYSTEM_ROLE: 'DELETE_SYSTEM_ROLE',
  UPDATE_SYSTEM_ROLE: 'UPDATE_SYSTEM_ROLE',
  DELETE_SYSTEM_ROLE_ASK_CONFIRMATION: 'DELETE_SYSTEM_ROLE_ASK_CONFIRMATION',
  GET_USERS_ASSIGNED_TO_SYSTEM_ROLE: 'GET_USERS_ASSIGNED_TO_SYSTEM_ROLE',
  REMOVE_SYSTEM_ROLE_FROM_USER: 'REMOVE_SYSTEM_ROLE_FROM_USER',
  ADD_SYSTEM_ROLE_TO_USER: 'ADD_SYSTEM_ROLE_TO_USER',

  GET_PROJECT_ROLES: 'GET_PROJECT_ROLES',
  GET_PROJECT_ROLE: 'GET_PROJECT_ROLE',
  CREATE_PROJECT_ROLE: 'CREATE_PROJECT_ROLE',
  DELETE_PROJECT_ROLE: 'DELETE_PROJECT_ROLE',
  UPDATE_PROJECT_ROLE: 'UPDATE_PROJECT_ROLE',
  DELETE_PROJECT_ROLE_ASK_CONFIRMATION: 'DELETE_PROJECT_ROLE_ASK_CONFIRMATION',

  ASSIGN_PERMISSIONS_TO_PROJECT_ROLE: 'ASSIGN_PERMISSIONS_TO_PROJECT_ROLE',
  UNASSIGN_PERMISSIONS_FROM_PROJECT_ROLE:
    'UNASSIGN_PERMISSIONS_FROM_PROJECT_ROLE',
  GET_PROJECT_ROLE_PERMISSIONS: 'GET_PROJECT_ROLE_PERMISSIONS',

  GET_SYSTEM_ROLE_PERMISSIONS: 'GET_SYSTEM_ROLE_PERMISSIONS',
  ASSIGN_SYSTEM_PERMISSIONS_TO_SYSTEM_ROLE:
    'ASSIGN_SYSTEM_PERMISSIONS_TO_SYSTEM_ROLE',
  UNASSIGN_SYSTEM_PERMISSIONS_FROM_SYSTEM_ROLE:
    'UNASSIGN_SYSTEM_PERMISSIONS_FROM_SYSTEM_ROLE',
  GET_SYSTEM_ROLE_PROJECT_PERMISSIONS: 'GET_SYSTEM_ROLE_PROJECT_PERMISSIONS',
  ASSIGN_PROJECT_PERMISSIONS_TO_SYSTEM_ROLE:
    'ASSIGN_PROJECT_PERMISSIONS_TO_SYSTEM_ROLE',
  UNASSIGN_PROJECT_PERMISSIONS_FROM_SYSTEM_ROLE:
    'UNASSIGN_PROJECT_PERMISSIONS_FROM_SYSTEM_ROLE',

  GET_PROJECT_PERMISSIONS: 'GET_PROJECT_PERMISSIONS',
  GET_SYSTEM_PERMISSIONS: 'GET_SYSTEM_PERMISSIONS',
};

export const SYSTEM_ROLE_FORM = {
  ID: 'systemRole',
  NAME: 'name',
  DESCRIPTION: 'description',
};

export const CREATE_SYSTEM_ROLE_FORM = {
  ...SYSTEM_ROLE_FORM,
  ID: 'createSystemRole',
};

export const PROJECT_ROLE_FORM = {
  ID: 'projectRole',
  NAME: 'name',
  DESCRIPTION: 'description',
};

export const CREATE_PROJECT_ROLE_FORM = {
  ...PROJECT_ROLE_FORM,
  ID: 'createProjectRole',
};

export const CREATE_SYSTEM_ROLE_MODAL_ID = 'CREATE_SYSTEM_ROLE_MODAL_ID';
export const CREATE_PROJECT_ROLE_MODAL_ID = 'CREATE_PROJECT_ROLE_MODAL_ID';

export const projectExecutePermissions = [
  ProjectPermission.EXECUTE,
  ProjectPermission.READ_ACTIVITY_COMMENTS_EXECUTE,
];

export const projectReportPermissions = [
  ProjectPermission.READ_ACTIVITY_COMMENTS_REPORT,
];

export const projectWellPermissions = [
  ProjectPermission.EDIT_WELL_DETAILS,
  ProjectPermission.APPROVE_WELL_DETAILS,
  ProjectPermission.EXPORT_WELL_DETAILS,
];

export const projectDetailsPermissions = [
  ProjectPermission.INITIATE_DELETE_PROJECT,
  ProjectPermission.EDIT_PROJECT_DETAILS,
];

export const projectPlanPermissions = [
  ProjectPermission.EDIT_TASKS,
  ProjectPermission.CREATE_TASKS,
  ProjectPermission.DELETE_TASKS,
  ProjectPermission.EDIT_TASK_WELL_DETAILS,
  // TODO: Figure out if we need this or not
  // ProjectPermission.EDIT_TASK_DETAILS,
  ProjectPermission.EDIT_TOOLSTRING,
  ProjectPermission.CREATE_TOOLSTRING,
  ProjectPermission.DELETE_TOOLSTRING,
  ProjectPermission.EDIT_SIMULATION,
  ProjectPermission.CREATE_SIMULATION,
  ProjectPermission.DELETE_SIMULATION,
  ProjectPermission.EDIT_ACTIVITIES,
  ProjectPermission.CREATE_ACTIVITY,
  ProjectPermission.DELETE_ACTIVITY,
  ProjectPermission.READ_ACTIVITY_COMMENTS,
];

export const projectTeamPermissions = [
  ProjectPermission.ADD_TEAM_MEMBERS,
  ProjectPermission.MANAGE_TEAM_MEMBERS_ACCESS,
  ProjectPermission.DELETE_TEAM_MEMBERS,
];

export const projectDocumentPermissions = [
  ProjectPermission.UPLOAD_DOCUMENTS,
  ProjectPermission.DOWNLOAD_DOCUMENTS,
  ProjectPermission.DELETE_ALL_DOCUMENTS,
];

export const projectLessonPermissions = [
  ProjectPermission.EDIT_LESSONS,
  ProjectPermission.CREATE_LESSONS,
  ProjectPermission.DELETE_LESSONS,
];

export const allProjectPermissions = [
  ...projectTeamPermissions,
  ...projectPlanPermissions,
  ...projectWellPermissions,
  ...projectLessonPermissions,
  ...projectDetailsPermissions,
  ...projectDocumentPermissions,
  ...projectReportPermissions,
];

export const systemProjectManagementPermissions = [
  SystemPermission.CREATE_PROJECTS,
  SystemPermission.DELETE_PROJECTS,
];

export const systemEquipmentTabPermissions = [
  SystemPermission.EQUIPMENT_TAB_VIEW,
  SystemPermission.ITEM_TAB_EDIT,
  SystemPermission.THIRD_PARTY_TAB_EDIT,
  SystemPermission.ITEM_VERIFICATION,
];

export const systemProjectPermissions = [ProjectPermission.FULL_ACCESS];

export const systemSettingsPermissions = [
  SystemPermission.MANAGE_USER_ACCESS,
  SystemPermission.MANAGE_SYSTEM_ROLES,
  SystemPermission.MANAGE_PROJECT_ROLES,
];

export const systemEquipmentPermissions = [SystemPermission.MANAGE_EQUIPMENT];

export const systemServicesPermissions = [SystemPermission.MANAGE_SERVICES];

export const systemWellPermissions = [SystemPermission.MANAGE_WELLS];

export const systemDataExchangePermissions = [SystemPermission.DATA_EXCHANGE];

export const systemSimulationsPermissions = [
  SystemPermission.MANAGE_SIMULATIONS,
];
export const systemAperioPermissions = [SystemPermission.WELL_ACCESS_ONLY];

export const integrityRulesManagement = [
  SystemPermission.INTEGRITY_RULES_MANAGEMENT,
];

export const systemDashboardConfigurationPermissions = [
  SystemPermission.DASHBOARD_CONFIGURATION_ACCESS,
];

export const systemPermissionGroups = [
  {
    title: 'Settings',
    showSubPermissions: true,
    permissions: systemSettingsPermissions,
  },
  {
    showSubPermissions: true,
    permissions: systemProjectManagementPermissions,
    title: 'Projects Management',
  },
  {
    title: 'Equipment Tab Access',
    showSubPermissions: true,
    permissions: systemEquipmentTabPermissions,
  },
  {
    title: 'Projects Full Access',
    permissions: systemProjectPermissions,
    rootPermissionType: PermissionType.PROJECT,
    rootPermissionId: ProjectPermission.FULL_ACCESS,
  },
  {
    title: 'Data Exchange',
    permissions: systemDataExchangePermissions,
    rootPermissionType: PermissionType.SYSTEM,
    rootPermissionId: SystemPermission.DATA_EXCHANGE,
  },
  {
    title: 'Wells',
    permissions: systemWellPermissions,
    rootPermissionId: SystemPermission.MANAGE_WELLS,
  },
  {
    hidden: true,
    title: 'Equipment',
    permissions: systemEquipmentPermissions,
    rootPermissionId: SystemPermission.MANAGE_EQUIPMENT,
  },
  {
    hidden: true,
    title: 'Services',
    permissions: systemServicesPermissions,
    rootPermissionId: SystemPermission.MANAGE_SERVICES,
  },
  {
    hidden: true,
    title: 'Simulations',
    permissions: systemSimulationsPermissions,
    rootPermissionId: SystemPermission.MANAGE_SIMULATIONS,
  },
  {
    title: 'Aperio',
    permissions: systemAperioPermissions,
    rootPermissionId: SystemPermission.WELL_ACCESS_ONLY,
  },
  {
    title: 'Dashboard Configuration',
    permissions: systemDashboardConfigurationPermissions,
    rootPermissionId: SystemPermission.DASHBOARD_CONFIGURATION_ACCESS,
  },
  {
    title: 'Integrity Rules Management',
    permissions: integrityRulesManagement,
    rootPermissionId: SystemPermission.INTEGRITY_RULES_MANAGEMENT,
  },
];

export const projectPermissionGroups = [
  {
    title: 'Project',
    permissions: allProjectPermissions,
    permissionGroups: [
      {
        title: 'Project details',
        permissions: projectDetailsPermissions,
      },
      {
        title: 'Well details',
        permissions: projectWellPermissions,
      },
      {
        title: 'Team',
        permissions: projectTeamPermissions,
      },
      {
        title: 'Documents',
        permissions: projectDocumentPermissions,
      },
      {
        title: 'Plan',
        permissions: projectPlanPermissions,
      },
      {
        title: 'Lessons',
        permissions: projectLessonPermissions,
      },
      {
        title: 'Report',
        permissions: projectReportPermissions,
      },
    ],
  },
  {
    title: 'Project Execute',
    permissions: projectExecutePermissions,
    showSubPermissions: true,
  },
];
