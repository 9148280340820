import {
  EMPTY_QUANTITY_LENGTH,
  EMPTY_QUANTITY_WITH_UNIT,
} from 'app/app.constants';
import AssetHistoryForm from './AssetHistoryForm';
import { getUnitForProperty } from 'features/equipment/equipment.helpers';
import { EDITABLE_TOOL_PROPERTIES } from 'features/equipment/equipment.constants';

const FormFields = AssetHistoryForm;

const AssetHistoryInitialFormValues = (
  defaultEventValue,
  defaultTestTypeValue,
  forceUnit,
  unitsOfMeasurePreference,
  selectedCustomUnitsOfMeasure,
) => ({
  [FormFields.TYPE_OF_EVENT]: defaultEventValue,
  [FormFields.TYPE_OF_TEST]: defaultTestTypeValue,
  [FormFields.RESULT]: '',
  [FormFields.DATE]: new Date(),
  [FormFields.TIME]: '',
  [FormFields.DESCRIPTION]: '',
  [FormFields.TYPE]: '',
  [FormFields.CABLE_CUT]: EMPTY_QUANTITY_LENGTH,
  [FormFields.CABLE_SIGNAL]: EMPTY_QUANTITY_WITH_UNIT('cs'),
  [FormFields.CABLE_RESISTANCE]: EMPTY_QUANTITY_WITH_UNIT('ohm'),
  [FormFields.WRAP_TEST]: EMPTY_QUANTITY_WITH_UNIT('WT'),

  //BOP TEST
  [FormFields.BOP_RAM]: '',
  [FormFields.BOP_LEAK_TYPE_DESCRIPTION]: '',
  [FormFields.CABLE_TORSION_WRAP]: '',

  //CABLE WRAP TEST
  [FormFields.CABLE_WRAP_TEST_STRAND]: '',
  [FormFields.CABLE_WRAP_TEST_STRAND_PASS_X]: '',
  [FormFields.CABLE_WRAP_TEST_STRAND_OUT_Y]: '',

  //CABLE TORTURE TEST
  [FormFields.CABLE_TORTURE_TEST_INNER_ARMOUR_DUCTILITY_SCORE]: '',
  [FormFields.CABLE_TORTURE_TEST_OUTER_ARMOUR_DUCTILITY_SCORE]: '',

  //CABLE CONTINUITY TEST
  [FormFields.CABLE_CONTINUITY_TEST_RESISTANCE]: '',

  //SIL2 TEST
  [FormFields.ASSET_SIL2_TEST_BOP]: '',
  [FormFields.ASSET_SIL2_TEST_BCS]: '',
  [FormFields.ASSET_SIL2_TEST_CABIN]: '',

  //CABLE WRAP BRAIDED MONO TEST
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_PASS_X]: '',
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_OUT_OF_Y]: '',
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_PASS_X]: '',
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_OUT_OF_Y]: '',

  //CABLE BREAK TEST
  [FormFields.CABLE_BREAK_TEST_FORCE]: EMPTY_QUANTITY_WITH_UNIT(forceUnit),

  //CABLE CUT MAINTENANCE
  [FormFields.CABLE_CUT_MAINTENANCE_DISTANCE]: EMPTY_QUANTITY_WITH_UNIT(
    getUnitForProperty(
      EDITABLE_TOOL_PROPERTIES.LENGTH,
      unitsOfMeasurePreference,
      selectedCustomUnitsOfMeasure,
    ),
  ),

  //CABLE SERVICE MAINTENANCE
  [FormFields.CABLE_SERVICE_MAINTENANCE_CABLE_LENGTH]: EMPTY_QUANTITY_WITH_UNIT(
    getUnitForProperty(
      EDITABLE_TOOL_PROPERTIES.LENGTH,
      unitsOfMeasurePreference,
      selectedCustomUnitsOfMeasure,
    ),
  ),
  [FormFields.CABLE_SERVICE_MAINTENANCE_MAX_OD]: EMPTY_QUANTITY_WITH_UNIT(
    getUnitForProperty(
      EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD,
      unitsOfMeasurePreference,
      selectedCustomUnitsOfMeasure,
    ),
  ),

  //CABLE SPOOLING MAINTENANCE
  [FormFields.CABLE_SPOOLING_DRUM_ITEM_NUMBER]: '',
  [FormFields.CABLE_SPOOLING_DRUM_SERIAL_NUMBER]: '',
});

export default AssetHistoryInitialFormValues;
