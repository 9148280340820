import { combineReducers } from 'redux-immutable';

import { APP_ACTIONS } from 'app/app.constants';

import fieldReducer from 'features/wells/field.reducer';
import toolReducer from 'features/projects/tool/tool.reducer';
import wellboreReducer from 'features/wells/wellbore.reducer';
import projectReducer from 'features/projects/project.entity.reducer';
import wellboreFileReducer from 'features/wells/wellboreFiles.reducer';
import cheetahJobReducer from 'features/operations/cheetahJob.reducer';
import facilityReducer from 'features/facilities/facility.reducer';
import equipmentReducer from 'features/equipment/equipment.reducer';
import dashboardReducer from 'features/projects/dashboard/dashboard.reducer';
import smLocationReducer from 'features/data-exchange/winch/smlocation.reducer';
import wellboreTrajectoryReducer from 'features/wells/wellboreTrajectory.reducer';
import activityReducer from 'features/projects/activities/activity.entity.reducer';
import wellboreSectionReducer from 'features/wells/sections/wellboreSection.reducer';
import wellboreElementReducer from 'features/wells/elements/wellboreElement.reducer';
import simulationReducer from 'features/projects/tasks/task/simulation/simulation.reducer';
import toolstringReducer from 'features/projects/tasks/task/toolstring/toolstring.reducer';
import projectDashboardReducer from 'features/projects/dashboard/projectDashboard.reducer';
import wellboreDetailReducer from 'features/projects/wellbore/details/wellboreDetail.reducer';
import projectDashboardCurveReducer from 'features/projects/dashboard/projectDashboardCurve.reducer';
import simulationResultReducer from 'features/projects/tasks/task/simulation/simulationResult.reducer';
import simulationFluidReducer from 'features/projects/tasks/task/simulation/fluids/simulationFluid.reducer';
import projectWellboreDetailReducer from 'features/projects/wellbore/details/projectWellboreDetails.reducer';
import simulationParameterReducer from 'features/projects/tasks/task/simulation/parameters/simulationParameter.reducer';
import wellControlReducer from 'features/wellControlDrillActivity/wellControl.reducer';
import simulationDynamicToolParameterReducer from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.reducer';
import projectGanttReducer from 'features/projects/projectGantt.entity.reducer';
import projectMobilisationReducer from 'features/projects/mobilisation/projectMobilisation.reducer';
import fieldDocumentsReducer from 'features/fields/fieldDocuments.reducer';

const entityReducers = combineReducers({
  tool: toolReducer,
  field: fieldReducer,
  fieldDocuments: fieldDocumentsReducer,
  project: projectReducer,
  activity: activityReducer,
  wellbore: wellboreReducer,
  dashboard: dashboardReducer,
  cheetahJob: cheetahJobReducer,
  toolString: toolstringReducer,
  simulation: simulationReducer,
  smlocation: smLocationReducer,
  facilities: facilityReducer,
  equipment: equipmentReducer,
  wellControl: wellControlReducer,
  wellboreFile: wellboreFileReducer,
  wellboreDetail: wellboreDetailReducer,
  wellboreSection: wellboreSectionReducer,
  wellboreElement: wellboreElementReducer,
  simulationFluid: simulationFluidReducer,
  simulationResult: simulationResultReducer,
  projectDashboard: projectDashboardReducer,
  wellboreTrajectory: wellboreTrajectoryReducer,
  simulationParameter: simulationParameterReducer,
  simulationDynamicToolParameter: simulationDynamicToolParameterReducer,
  projectWellboreDetail: projectWellboreDetailReducer,
  projectDashboardCurve: projectDashboardCurveReducer,
  ganttProjects: projectGanttReducer,
  projectMobilisation: projectMobilisationReducer,
});

const entityReducer = (state, action) => {
  if (action.type === APP_ACTIONS.LOGOUT) {
    state = undefined;
  }

  return entityReducers(state, action);
};

export default entityReducer;
