import { compose } from 'redux';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { useHeader } from 'app/hooks/useHeader';
import BasePage from 'app/components/BasePageDense';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import HasSystemPermission from 'app/components/HasSystemPermission';
import { EUROPE_MAP_CENTER_COORDS, SystemPermission } from 'app/app.constants';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';

import {
  EQUIPMENT_ACTIONS,
  EQUIPMENT_ACTIVE_TAB,
} from 'features/equipment/equipment.constants';

import CablesContainer from 'features/equipment/cables/CablesContainer';
import MwlToolsContainer from 'features/equipment/mwlTools/MwlToolsContainer';
import EwlToolsContainer from 'features/equipment/ewlTools/EwlToolsContainer';
import ToolAssembliesContainer from 'features/equipment/toolAssemblies/ToolAssembliesContainer';
import ThirdPartyToolsContainer from 'features/equipment/thirdPartyTools/ThirdPartyToolsContainer';
import GoogleMap from 'app/components/GoogleMap';
import BhaTemplatesContainer from 'features/equipment/bhaTemplates/BhaTemplatesContainer';
import SurfaceEquipmentsContainer from 'features/equipment/surfaceEquipment/SurfaceEquipmentsContainer';

const activeTabHandler = (activeTab) => {
  switch (activeTab) {
    default:
      return null;
    case EQUIPMENT_ACTIVE_TAB.CABLES:
      return <CablesContainer />;
    case EQUIPMENT_ACTIVE_TAB.EWL_TOOLS:
      return <EwlToolsContainer />;
    case EQUIPMENT_ACTIVE_TAB.MWL_TOOLS:
      return <MwlToolsContainer />;
    case EQUIPMENT_ACTIVE_TAB.BHA_TEMPLATES:
      return <BhaTemplatesContainer />;
    case EQUIPMENT_ACTIVE_TAB.TOOL_ASSEMBLIES:
      return <ToolAssembliesContainer />;
    case EQUIPMENT_ACTIVE_TAB.THIRD_PARTY_TOOLS:
      return <ThirdPartyToolsContainer />;
    case EQUIPMENT_ACTIVE_TAB.SURFACE_EQUIPMENT:
      return <SurfaceEquipmentsContainer />;
  }
};

const EquipmentContainer = ({ classes, dataState, breadcrumb }) => {
  useHeader({
    subTitle: breadcrumb,
    logoNavigation: '/operations',
  });

  const history = useHistory();
  const { userPermissions } = useSystemPermissions();

  const [activeTab, setActiveTab] = useState(EQUIPMENT_ACTIVE_TAB.MWL_TOOLS);
  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (!userPermissions.includes(SystemPermission.EQUIPMENT_TAB_VIEW)) {
      history.goBack();
    }
  }, [userPermissions, history]);

  return (
    <BasePage dataState={dataState}>
      <GoogleMap center={EUROPE_MAP_CENTER_COORDS} />
      <HasSystemPermission
        permissions={[
          SystemPermission.ITEM_TAB_EDIT,
          SystemPermission.EQUIPMENT_TAB_VIEW,
        ]}
      >
        <Grid container className={classes.contentContainer}>
          <Tabs
            value={activeTab}
            onChange={handleTabs}
            className={classes.tabsContainer}
          >
            <Tab label="MWL Tools" value={EQUIPMENT_ACTIVE_TAB.MWL_TOOLS} />
            <Tab label="EWL Tools" value={EQUIPMENT_ACTIVE_TAB.EWL_TOOLS} />
            <Tab
              label="3rd party Tools"
              value={EQUIPMENT_ACTIVE_TAB.THIRD_PARTY_TOOLS}
            />
            <Tab
              label="Tool Assemblies"
              value={EQUIPMENT_ACTIVE_TAB.TOOL_ASSEMBLIES}
            />
            <Tab
              label="BHA Templates"
              value={EQUIPMENT_ACTIVE_TAB.BHA_TEMPLATES}
            />
            <Tab label="Cables" value={EQUIPMENT_ACTIVE_TAB.CABLES} />
            <Tab
              label="Surface Equipment"
              value={EQUIPMENT_ACTIVE_TAB.SURFACE_EQUIPMENT}
            />
          </Tabs>
          <Grid item container className={classes.tabPanel}>
            {activeTabHandler(activeTab)}
          </Grid>
        </Grid>
      </HasSystemPermission>
    </BasePage>
  );
};

const styles = (theme) => ({
  contentContainer: {
    top: 0,
    bottom: 0,
    flexWrap: 'nowrap',
    position: 'absolute',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  tabsContainer: {
    borderRadius: 3,
    backgroundColor: theme.palette.tabs.background.main,
  },
  tabPanel: {
    flex: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.tabs.background.main,
    overflow: 'auto',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.PAGE_LOADED,
      EQUIPMENT_ACTIONS.CREATE_TOOL,
      EQUIPMENT_ACTIONS.TOOLS_LOADED,
      EQUIPMENT_ACTIONS.SEARCH_TOOLS,
      EQUIPMENT_ACTIONS.CABLES_LOADED,
      EQUIPMENT_ACTIONS.M3_ITEM_SEARCH,
      EQUIPMENT_ACTIONS.SUPPLIERS_LOADED,
      EQUIPMENT_ACTIONS.TOOL_GROUPS_LOADED,
      EQUIPMENT_ACTIONS.TOOL_CONNECTORS_LOADED,
      EQUIPMENT_ACTIONS.BHA_TEMPLATES_LOADED,
      EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
      EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS,
    ),
  })),
  withStyles(styles),
)(EquipmentContainer);
